import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import { AUTH_TOKEN, USER_INFO } from "../constants/AuthConstant";
import { Base64 } from "js-base64";

let webConfig = [];

export const RESET_WEB_CONFIG = () => {
  webConfig = [];
};

export const WEB_CONFIG = (option) => {
  if (webConfig) {
    webConfig = localStorage.getItem(USER_INFO);
    if (webConfig) {
      webConfig = JSON.parse(Base64.decode(webConfig));
    } else {
      webConfig = {};
    }
  }
  return webConfig[option];
};

export const CAN_VIEW_MODULE = (moduleid) => {
  const allowed_modules = WEB_CONFIG("allowed_modules");
  if (!allowed_modules) return false;
  if (Array.isArray(moduleid)) {
    const shared = allowed_modules.filter(function (n) {
      return moduleid.indexOf(n) !== -1;
    });
    return shared.length > 0;
  }
  return allowed_modules.includes(moduleid);
};

export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = APP_PREFIX_PATH + "/home/index";
export const UNAUTHENTICATED_ENTRY = "/login";

export const APP_VERSION = 1.0;
export const APP_NAME = "ArabianFal";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const IMAGE_PLACEHOLDER_URL = `${env.SITE_URL}/storage/placeholder.png`;
export const GOOGLE_MAPS_API_KEY = "AIzaSyDMsSw3WYis99-AsPGPPLraK5vZAfnbUnc";

export const GET_AUTH_HEADER = () => {
  const jwtToken = localStorage.getItem(AUTH_TOKEN);
  if (jwtToken) {
    return {
      authorization: "Bearer " + jwtToken,
      "app-version": APP_VERSION,
    };
  }
  return false;
};

export const getAllZones = (districtOnly) => {
  let options = [];
  let dc = [];
  WEB_CONFIG("settings").districts.forEach((district) => {
    dc = { value: district.id, label: district.name, children: [] };
    if (district.zones.length > 0 && !districtOnly) {
      district.zones.forEach((zone) => {
        dc.children.push({ value: zone.id, label: zone.name, children: [] });
      });
    }
    options.push(dc);
  });

  const dc_id = WEB_CONFIG("dc_id");
  const zone_id = WEB_CONFIG("zone_id");
  const role = WEB_CONFIG("role").name;

  if (["supervisor"].includes(role)) {
    options = options.filter(function (dc) {
      return parseInt(dc_id) === parseInt(dc.value);
    });
    options[0].children = options[0].children.filter(function (zone) {
      return parseInt(zone_id) === parseInt(zone.value);
    });
  } else if (role === "foreman") {
    options = options.filter(function (dc) {
      return parseInt(dc_id) === parseInt(dc.value);
    });
  }

  return options;
};

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  blankLayout: false,
};
