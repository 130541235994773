import fetch from "auth/FetchInterceptor";

const apiService = {};

//================== Exam Categories ===========
apiService.getCategories = function (data) {
  return fetch({
    url: "exam-categories/list",
    method: "get",
    params: data,
  });
};

apiService.deleteCategory = function (cat_id) {
  return fetch({
    url: "exam-categories/" + cat_id + "/delete",
    method: "delete",
  });
};

apiService.addCategory = function (data) {
  return fetch({
    url: "exam-categories/create",
    method: "post",
    data,
  });
};

apiService.editCategory = function (data) {
  const { id, name } = data;
  return fetch({
    url: "exam-categories/" + id + "/update",
    method: "put",
    data: { name },
  });
};

//================== Exams ===========

apiService.getExams = function (data) {
  return fetch({
    url: "exams/list",
    method: "get",
    params: data,
  });
};
apiService.deleteExam = function (exam_id) {
  return fetch({
    url: "exams/" + exam_id + "/delete",
    method: "delete",
  });
};
apiService.addExam = function (data) {
  return fetch({
    url: "exams/create",
    method: "post",
    data,
  });
};

apiService.editExam = function (data) {
  const { exam_id, ...rest } = data;
  return fetch({
    url: "exams/" + exam_id + "/update",
    method: "put",
    data: { ...rest },
  });
};

apiService.uploadImage = function (data) {
  return fetch({
    url: "exams/questions/upload",
    method: "post",
    data: data,
  });
};
//================== Trainees ===========
apiService.getTrainees = function (data) {
  return fetch({
    url: "trainers/list",
    method: "get",
    params: data,
  });
};

apiService.deleteTrainee = function (trainee_id) {
  return fetch({
    url: "trainers/" + trainee_id + "/delete",
    method: "delete",
  });
};
apiService.addTrainee = function (data) {
  return fetch({
    url: "trainers/create",
    method: "post",
    data,
  });
};
apiService.editTrainee = function (data) {
  const { id, ...rest } = data;
  return fetch({
    url: "trainers/" + id + "/update",
    method: "put",
    data: { ...rest },
  });
};

apiService.getTraineeProfile = function (id) {
  return fetch({
    url: "trainers/" + id + "/show",
    method: "get",
  });
};

apiService.getTraineeResults = function (id) {
  return fetch({
    url: "results/list",
    method: "get",
    params: { userid: id },
  });
};

apiService.getTraineeResultsPaging = function (params) {
  return fetch({
    url: "results/list",
    method: "get",
    params: params,
  });
};

apiService.getExamResult = function (id) {
  return fetch({
    url: "results/" + id + "/show",
    method: "get",
  });
};

apiService.exportResults = function () {
  return fetch({
    url: "results/export",
    method: "get",
  });
};

//================== Admins ===========
apiService.getAdmins = function (data) {
  return fetch({
    url: "admins/list",
    method: "get",
    params: data,
  });
};

apiService.deleteAdmin = function (trainee_id) {
  return fetch({
    url: "admins/" + trainee_id + "/delete",
    method: "delete",
  });
};
apiService.addAdmin = function (data) {
  return fetch({
    url: "admins/create",
    method: "post",
    data,
  });
};
apiService.editAdmin = function (data) {
  const { id, ...rest } = data;
  return fetch({
    url: "admins/" + id + "/update",
    method: "put",
    data: { ...rest },
  });
};

apiService.changeAdminPassword = function (data) {
  const { id, ...rest } = data;
  return fetch({
    url: "admins/change-password/" + id,
    method: "post",
    data:rest,
  });
};
apiService.getByUserId = function (falco_id) {
  return fetch({
    url: "user/" + falco_id,
    method: "get",
  });
};
apiService.getPermissions = function () {
  return fetch({
    url: "permissions/list",
    method: "get",
  });
};
apiService.updatePermissions = function (data) {
  const { id, permissions } = data;
  return fetch({
    url: "permissions/" + id + "/update",
    method: "post",
    data: { permissions: permissions },
  });
};
apiService.getAdminById = function (id) {
  return fetch({
    url: "admins/" + id + "/profile",
    method: "get",
  });
};
apiService.getHome = function () {
  return fetch({
    url: "admins/home",
    method: "get",
  });
};

//////////////// Agencies //////////////////
//================== Trainees ===========
apiService.getAgencies = function (data) {
  return fetch({
    url: "agencies/list",
    method: "get",
    params: data,
  });
};

apiService.deleteAgency = function (id) {
  return fetch({
    url: "agencies/" + id + "/delete",
    method: "delete",
  });
};
apiService.addAgency = function (data) {
  return fetch({
    url: "agencies/create",
    method: "post",
    data,
  });
};
apiService.editAgency = function (data) {
  const { id, ...rest } = data;
  return fetch({
    url: "agencies/" + id + "/update",
    method: "put",
    data: { ...rest },
  });
};

apiService.getAgencyProfile = function (id) {
  return fetch({
    url: "agencies/" + id + "/show",
    method: "get",
  });
};

apiService.getAgencyResults = function (id) {
  return fetch({
    url: "results/list",
    method: "get",
    params: { userid: id },
  });
};

apiService.getAgencyResultsPaging = function (params) {
  return fetch({
    url: "results/list",
    method: "get",
    params: params,
  });
};

export default apiService;
