const dev = {
  API_ENDPOINT_URL: "https://fal-tc-backend.detailslocal.com/api",
  // API_ENDPOINT_URL:"http://127.0.0.1:8000/api",
  SITE_URL: "https://fal-tc.detailslocal.com",
};

const prod = {
  API_ENDPOINT_URL: "https://backend.exam-center.arabianfal.net/api",
  SITE_URL: "https://exam-center.arabianfal.net",
};

const test = {
  API_ENDPOINT_URL: "http://127.0.0.1:8000/api",
  SITE_URL: "https://fal-tc.detailslocal.com",
};

const getEnv = () => {
  if (window.location.href.search("localhost") >= 0) {
    return test;
  }

  if (window.location.href.search("fal-tc-admin.") >= 0) {
    return dev;
  }

  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
