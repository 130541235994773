import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "first-time-login",
    path: `${AUTH_PREFIX_PATH}/first-time-login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/first-time-login")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "404",
    path: `${AUTH_PREFIX_PATH}/404`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "404",
    path: `${APP_PREFIX_PATH}/404`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
];

const routeRestGroup = (component) => {
  return [
    { folder: component, path: null, component: "list" },
    { folder: component, path: null, component: "add" },
    { folder: component, path: ":id/edit", component: "edit" },
    { folder: component, path: "edit/:id", component: "edit" },
    { folder: component, path: "view/:id", component: "view" },
  ];
};

let protectedLinks = [];

const anotherRoutes = [
  { folder: "admins", path: "", component: "change-password" },
  { folder: "home", path: "", component: "index" },
  { folder: "results", path: "", component: "list" },
  {
    folder: "trainees",
    path: "exam-result/:id",
    component: "exam-result",
  },
];

protectedLinks = [
  ...protectedLinks,
  ...routeRestGroup("camps"),
  ...routeRestGroup("exams"),
  ...routeRestGroup("category"),
  ...routeRestGroup("trainees"),
  ...routeRestGroup("admins"),
  ...routeRestGroup("agencies"),
  ...anotherRoutes,
];

export const protectedRoutes = protectedLinks.map((link, index) => {
  return {
    key: index,
    path: `${APP_PREFIX_PATH}/${link.folder}/${
      link.path ? link.path : link.component
    }`,
    component: React.lazy(() =>
      import(`views/app-views/${link.folder}/${link.component}`)
    ),
  };
});
